// import moment from 'moment'

import dayjs from "dayjs"
import { CANDIDATE_TIME_SESSION_KEY, CONSTANTS } from "src/config/constants"
import { Status } from "src/models"

// export const formatTime = (date: any, format: string = 'YYYY/MM/DD') => {
//   return moment(new Date(date)).format(format)
// }

export const genString = (length: number) => {
    let string = ''
    let chars =
        '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    for (let i = 1; i <= length; i++) {
        var char = Math.floor(Math.random() * chars.length + 1)
        string += chars.charAt(char)
    }
    return string
}

export function handleSortByStartTime(status: string) {
    switch (status) {
        case Status.ADJUSTING:
            return 'adjustment_start_date';
        case Status.FINALIZED:
            return 'start_time'
        case Status.DRAFT:
            return 'adjustment_start_date'
        case Status.PAST:
            return 'start_time';
        default:
            return 'start_time';
    }
}

export function cutString(text: string, number: number = 20) {
    if (!text) return text;

    if (text.length <= number) {
        return text;
    }

    return text.slice(0, number) + '..';
}

export function replaceGaroonName(user: any, width = 768, forMail = false) {
    const surName = user?.sur_name || '';
    const givenName = user?.given_name || '';
    
    let displayName = surName.length > 0 && givenName.length > 0 ? `${surName}ﾠ${givenName}` : user?.name;
    if (forMail || width < 600) {
        displayName = surName.length > 0 && givenName.length > 0 ? `${surName}\x20${givenName}` : user?.name;
    }

    return displayName;
}

export function getSessionCandidateTimeSelected() {
    try {
      const data = sessionStorage.getItem(CANDIDATE_TIME_SESSION_KEY);
      return data ? JSON.parse(data) : {};

    } catch (error) {
      return {};
    }
}

export function generateTextToCopy(data: any, url: string, type: number) {
    let textToCopy = 
type !== CONSTANTS.TYPE_ADJUSTMENT.SINGLE_EXTERNAL ? `
候補日時:
${data.map((value: any) => {
    return `${dayjs(value.start_time).format(
        `YYYY/M/D(${
            CONSTANTS.DAY_OF_WEEK[dayjs(value.start_time).day()]
        }) ${dayjs(value.start_time).format('HH:mm')}～（${
            value.duration
                ? value.duration === 1439
                    ? '終日'
                    : value.duration + '分間'
                : ''
        }）`
    )}\n`;
}).join('')} 
` : '';

textToCopy += `日程調整URL:
${url}`;

    return textToCopy.trim();
}